/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-return-assign */
/* eslint-disable react/jsx-indent */
import React, { useState, useContext, useRef, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { useParams, useHistory, Redirect, Link } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { GET_USER_TYPE } from 'src/graphql/operations/queries/portfolio'
import { PortfolioMediaFileLink } from 'src/graphql/models/PortfolioDetail'
import { UserType } from 'src/graphql/models/User'

import { loadPortfolioMutation } from 'src/graphql/operations/mutations'
import { notistackOptions } from 'src/configs/notistackOptions'

import { Content, SectionColumn, TdNoEllipsis } from 'src/styles/layout'
import {
  AccountPortfolioType,
  BUYER,
  INTERNAL,
  PermissionCodeAccess,
  PortfolioStatusType,
  SELLER,
} from 'src/utils/constants'
import { AbilityContext, Can } from 'src/context/Can'
import { AuthContext } from 'src/context/AuthenticationContext'
import {
  ACCOUNT_DETAIL,
  FORWARD_FLOW_DETAIL,
  PORTFOLIO,
  PORTFOLIO_DETAIL,
} from 'src/routes'
import { isBefore } from 'date-fns'
import { numberCurrencyDollar, numberToPercentage } from 'src/utils/numbers'
import {
  Box,
  Grid,
  Typography,
  TextField,
  IconButton,
  Button,
  Checkbox,
  MenuItem,
  Menu,
  Tabs,
  Tooltip,
  Accordion,
  Autocomplete,
  Skeleton,
} from '@mui/material'
import { TabContext } from '@mui/lab'
import { formatDate } from 'src/utils/date'

import { capitalize } from 'src/utils/text'
import DTAccordion from 'src/components/Accordion'
import { Canada } from 'react-flat-flags'
import Header from 'src/components/Header'

import { renderNumber } from 'src/utils/formatKendoColumns'
import {
  useCustomLazyQuery,
  useCustomQuery,
} from 'src/infra/react-query-wrapper'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import {
  getAllPortfolioSteps,
  getManagePortfolioAccountsByLenderTemplateFile,
  getPortfolioAccounts,
  getPortfolioBuyerBids,
  getPortfolioCardGridItems,
  getPortfolioErrorMsg,
  getPortfolioFileUri,
  getPortfolioFilesLink,
  getPortfolioMediaFilesLink,
  refetchAllPortfolioSteps,
} from 'src/data/features/get/portfolio/portfolio'
import {
  ButtonDropdown,
  ButtonDropdownItem,
  Colors,
  DataTable,
  DataTableState,
  ExportExcelButton,
  Flex,
  Icon,
  ModalDialog,
  UploadDragDrop,
  fParseXlsxFile,
  textSecondary,
} from 'everchain-uilibrary'
import HistorySteps from './components/HistorySteps'
import SalesDirectorInfo from './components/SalesDirectorInfo'
import DisplayAggregates from './components/DisplayAggregates'
import DisplayAuction from './components/DisplayAuction'
import PortfolioAnalyticsGraphcs from './components/PortfolioAnalyticsGraphcs'
import {
  ChipStatusError,
  TabCustom,
  TooltipTitleStep,
  AccordionSummaryCustom2,
  AccordionTitle,
} from './styles'
import PortfolioCollectionActivityDisplay from './components/PortfolioCollectionActivityDisplay'
import PortfolioNavigation from './components/PortfolioNavigation'
import SubmitScrub from './components/SubmitScrub'
import SellerAwardedAction from './components/SellerAwardedAction'
import UpdateCloseFundDates from './components/UpdateCloseFundDates'
import ConfirmSellerFee from './components/ConfirmSellerFee'
import ListPortfolio from './components/ListPortfolio'
import ConfirmBuyerFunding from './components/ConfirmBuyerFunding'
import ConfirmSellerFunding from './components/ConfirmSellerFunding'
import ConfirmFinalFunding from './components/ConfirmFinalFunding'
import PurchaseSaleAgreement from './components/PurchaseSaleAgreement'
import BuyerPlaceBid from './components/BuyerPlaceBid'
import SellerBidSummary from './components/SellerBidSummary'
import BuyerAccess from './components/BuyerAccess'
import ScoringRequest from './components/ScoringRequest'
import UnmaskDataRequest from './components/UnmaskDataRequest'
import AdminCloseAuction from './components/AdminCloseAuction'
import AdminAddDisclosure from './components/AdminAddDisclosure'
import AdminRegenerateFile from './components/AdminRegenerateFile'
import UpdateBuyerFee from './components/AdminUpdateBuyerFee'
import UpdateSellerFee from './components/AdminUpdateSellerFee'
import SaveBid from './components/SaveBid'
import PortfolioFileLinks from './components/PortfolioFileLinks'
import PortfolioInvoices from './components/PortfolioInvoices'
import ApproveUnmaskedDataRequest from './components/ApproveUnmaskedDataRequest'
import BidReview from './components/BidReview'
import PortfolioHistory from './components/PortfolioHistory'
import FundingInformation from './components/FundingInformation'
import AdminChangeBuyer from './components/AdminChangeBuyer'
import AdminAddDownload from './components/AdminAddDownload'
import SellerReviewForwardFlow from './components/SellerReviewForwardFlow'
import PostSaleRequestAccordion from '../PostSale/PostSaleRequestAccordion'
import SellerSurveyFile from './components/SellerSurveyFile'
import SubmitScrubAwaitingSeller from './components/SubmitScrubAwaitingSeller'
import ListPortfolioImmediately from './components/ListPortfolioImmediately'
import PortfolioMediaFileLinks from './components/PortfolioMediaFileLinks'
import {
  PortfolioBid,
  PortfolioDetailAccounts,
  PortfolioGridItem,
} from 'src/data/features/get/portfolio/types'
import {
  acceptPortfolioDisclosure,
  excludeOutOfStatAccounts,
  managePortfolioAccountsByLender,
  managePortfolioAccountsByRow,
} from 'src/data/features/post/portfolio/portfolio'
import {
  ManagePortfolioAccountsByLenderRequest,
  ManagePortfolioAccountsByRowRequest,
} from 'src/data/features/post/portfolio/types'
import { downloadBase64File } from 'src/utils/fileDownload'
import { Paged } from 'src/data/types'
import {
  checkAndNotifyIfMissing,
  formatDataToExport,
  getStandardUri,
} from 'src/utils/common'
import { PortfolioStatus } from 'src/utils/portfolioStatus'
import PortfolioInfoAccordion from './components/PortfolioInfoAccordion'

interface ParamTypes {
  portfolioId: string
  cardName: string
  subFilter?: string
}
interface Messages {
  [key: string]: string
}
const notifySuccess = notistackOptions('success')
const notifyError = notistackOptions('error')

const convertLinks = (text: string) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g

  return text.split(urlRegex).map((part, index) => {
    // If the part matches the URL pattern, return a clickable link
    if (part.match(urlRegex)) {
      return (
        <a
          key={index}
          href={part}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: 'blue' }}
        >
          {part}
        </a>
      )
    }
    // If it's not a URL, return the text part as-is
    return part
  })
}

const PortfolioDetail: React.FC = () => {
  const statusesToShowPostSaleAccordion = [
    PortfolioStatusType.Awarded,
    PortfolioStatusType.PendingPSA,
    PortfolioStatusType.AwaitingSellerFee,
    PortfolioStatusType.PendingBuyerFunding,
    PortfolioStatusType.PendingSellerFunding,
    PortfolioStatusType.PendingFinalConfirmation,
    PortfolioStatusType.Funded,
  ]
  const [reason, setReason] = useState({
    value: '',
    error: '',
  })

  const [openExcludeOutOfStatModal, setOpenExcludeOutOfStatModal] =
    useState<boolean>(false)

  const [manageAccountsByRowRequest, setManageAccountsByRowRequest] =
    useState<ManagePortfolioAccountsByRowRequest>()
  const [manageAccountsByLenderRequest, setManageAccountsByLenderRequest] =
    useState<ManagePortfolioAccountsByLenderRequest>()
  const [uploadFile, setUploadFile] = useState<any>(undefined)
  const [showManageAccountsFileModal, setShowManageAccountsFileModal] =
    useState<boolean>(false)
  const [isGettingTemplateFile, setIsGettingTemplateFile] =
    useState<boolean>(false)

  const [excludingAccountsOutOfStat, setExcludingAccountsOutOfStat] =
    useState<boolean>(false)
  const [openAccounts, setOpenAccounts] = useState<boolean>(false)
  const [openAnalysis, setOpenAnalysis] = useState<boolean>(false)

  const [removeAccountsReason, setRemoveAccountsReason] = useState<
    string | null
  >(null)

  const handleExclusionCheck = (props: any) => {
    if (
      accountsSelectedToExclusion.find(
        (rowIndex) => rowIndex === props.dataItem['rowIndex']
      ) !== undefined
    )
      return true

    return false
  }

  const handleRestoreCheck = (props: any) => {
    if (
      accountsSelectedToRestore.find(
        (rowIndex) => rowIndex === props.dataItem['rowIndex']
      ) !== undefined
    )
      return true

    return false
  }

  const [accountsSelectedToExclusion, setAccountsSelectedToExclusion] =
    useState<number[]>([])

  const [accountsSelectedToRestore, setAccountsSelectedToRestore] = useState<
    number[]
  >([])

  const reactQueryClient = useQueryClient()

  const { isFetching: updatingAccountsByRow } = useCustomQuery<any>(
    ['managePortfolioAccountsByRow', manageAccountsByRowRequest],
    async () => {
      if (manageAccountsByRowRequest) {
        await managePortfolioAccountsByRow(manageAccountsByRowRequest)
          .then(() => {
            enqueueSnackbar('Accounts updated', notifySuccess)
            setAccountsSelectedToExclusion([])
            setAccountsSelectedToRestore([])
            reactQueryClient.refetchQueries({
              queryKey: ['getPortfolioFilesLink'],
            })
            reactQueryClient.refetchQueries({
              queryKey: ['getPortfolioAccounts'],
            })
          })
          .catch(() => {
            enqueueSnackbar('Error on updating accounts', notifyError)
          })
          .finally(() => {
            setManageAccountsByRowRequest(undefined)
          })
      }
    },
    { enabled: !!manageAccountsByRowRequest }
  )

  const { isFetching: updatingAccountsByLender } = useCustomQuery<any>(
    ['managePortfolioAccountsByLender', manageAccountsByLenderRequest],
    async () => {
      if (manageAccountsByLenderRequest) {
        await managePortfolioAccountsByLender(manageAccountsByLenderRequest)
          .then((accountsUpdated) => {
            enqueueSnackbar(
              `Accounts updated: ${accountsUpdated}`,
              notifySuccess
            )
            setAccountsSelectedToExclusion([])
            setAccountsSelectedToRestore([])
            setShowManageAccountsFileModal(false)
            setUploadFile(undefined)
            reactQueryClient.refetchQueries({
              queryKey: ['getPortfolioFilesLink'],
            })
            reactQueryClient.refetchQueries({
              queryKey: ['getPortfolioAccounts'],
            })
          })
          .catch(() => {
            enqueueSnackbar('Error on updating accounts', notifyError)
          })
          .finally(() => {
            setManageAccountsByLenderRequest(undefined)
          })
      }
    },
    { enabled: !!manageAccountsByLenderRequest }
  )

  const onManageAccounts = () => {
    let portfolioRowIndexes: number[] = []
    let command: string = ''

    if (tabDetailsIndex === '') command = 'exclude'
    if (tabDetailsIndex === 'excluded') command = 'restore'

    if (command === 'exclude') portfolioRowIndexes = accountsSelectedToExclusion
    if (command === 'restore') portfolioRowIndexes = accountsSelectedToRestore

    setManageAccountsByRowRequest({
      command,
      portfolioId,
      portfolioRowIndexes,
      removeComment: removeAccountsReason,
    })
  }

  const { userPermissions, profileClient } = useContext(AuthContext)

  const [gridState, setGridState] = useState<DataTableState>({
    skip: 0,
    take: 25,
    filter: undefined,
    sort: undefined,
  })
  const ability = useContext(AbilityContext)
  const queryClient = useQueryClient()
  const [totalDataSize, setTotalDataSize] = useState<number>(5)
  const [openDeleteCofirm, setOpenDeleteCofirm] = useState(false)
  const [isGettingPortfolioUri, setIsGettingPortfolioUri] = useState(false)
  const [openDisclosure, setOpenDisclosure] = useState<boolean>(false)
  const [tabDetailsIndex, setTabDetailsIndex] = useState('')

  const { data: userType } = useQuery<UserType>(GET_USER_TYPE)

  const { portfolioId, cardName, subFilter } = useParams<ParamTypes>()
  const history = useHistory()

  const [fileType, setFileType] = useState<string>('')

  const { data: portfolioFiles, isFetching: loadingPortfolioFiles } =
    useCustomQuery<any>(
      ['getPortfolioFilesLink', portfolioId],
      async () => getPortfolioFilesLink(portfolioId),
      { enabled: true, cacheTime: 0 }
    )

  const {
    data: portfolioDetailData,
    isFetching: loading,
    isError: portfolioDetailError,
    isFetched: fetchedPortfolioData,
  } = useCustomQuery<any>(
    ['getAllPortfolioSteps', portfolioId],
    async () => {
      return getAllPortfolioSteps(portfolioId)
        .then((result: any) => {
          if (!result?.isDisclosureAccepted) setOpenDisclosure(true)
          return result
        })
        .catch(() => {
          enqueueSnackbar('Portfolio not found.', notifyError)
        })
    },
    { enabled: true, cacheTime: 0 }
  )

  const { isFetching: isFetchingDownloadTemplateFile } = useCustomQuery<any>(
    ['getManagePortfolioAccountsByLenderTemplateFile'],
    async () =>
      getManagePortfolioAccountsByLenderTemplateFile().then((data) => {
        setIsGettingTemplateFile(false)
        downloadBase64File(data)
      }),
    { enabled: isGettingTemplateFile, cacheTime: 0 }
  )

  const { profileBusiness } = useContext(AuthContext)

  const lastDatePortfolioAnalytics =
    portfolioDetailData?.lastPortfolioAnalyticsDate

  const isBk = AccountPortfolioType.Bankruptcy === portfolioDetailData

  const hasAccounts = !!portfolioDetailData?.numberOfAccounts

  const isCanada = portfolioDetailData?.portfolioCountry === 'CA'
  const isUK = portfolioDetailData?.portfolioCountry === 'UK'
  const portfolioCountry = portfolioDetailData?.portfolioCountry

  const isInternal = userType?.userType === INTERNAL
  const isPortfolioSeller =
    profileBusiness?.filter(
      (item: any) => item.Id === portfolioDetailData?.sellerId
    )?.length === 1

  const isPortfolioBuyer =
    profileBusiness?.filter(
      (item: any) => item.Id === portfolioDetailData?.buyerId
    )?.length === 1

  const { data: portfolioAccounts, isFetching: loadingAccount } =
    useCustomQuery<PortfolioDetailAccounts>(
      ['getPortfolioAccounts', portfolioId, tabDetailsIndex, gridState],
      async () =>
        getPortfolioAccounts(
          portfolioId,
          tabDetailsIndex,
          JSON.stringify(gridState)
        ).then((responseData) => {
          setTotalDataSize(responseData.totalOfDataResult ?? 5)

          return responseData
        }),
      { enabled: openAccounts, cacheTime: 0 }
    )

  const { data: portfolioGridData, isFetching: portfolioGridLoading } =
    useCustomQuery<Paged<PortfolioGridItem>>(
      [
        'getPortfolioCardGridItems',
        userType?.userType,
        cardName,
        {},
        subFilter,
      ],
      async () =>
        getPortfolioCardGridItems(
          userType?.userType,
          cardName,
          {},
          null,
          subFilter
        ),
      { enabled: !!cardName, cacheTime: 0 }
    )

  const { data: buyerBidsData, isFetching: loadingPlacedBids } = useCustomQuery<
    PortfolioBid[]
  >(
    ['getPortfolioBuyerBids', portfolioId],
    async () => getPortfolioBuyerBids(portfolioId),
    { enabled: userType?.userType === BUYER && !!portfolioId, cacheTime: 0 }
  )

  const handleSetOpenAccounts = (value: boolean) => {
    setOpenAccounts(value)
  }
  const renderCurrency = (props: any) => {
    return (
      <td {...props} style={{ textAlign: 'right' }}>
        {numberCurrencyDollar(props.dataItem[props.field], portfolioCountry)}
      </td>
    )
  }

  const renderDate = (props: any) => {
    return (
      <td {...props}>
        {props.dataItem[props.field]
          ? formatDate(
              new Date(props.dataItem[props.field]),
              '',
              portfolioCountry
            )
          : props.dataItem[props.field]}
      </td>
    )
  }

  const { usePurgePortfolio } = loadPortfolioMutation

  const { enqueueSnackbar } = useSnackbar()

  const handleClose = () => {
    setOpenDeleteCofirm(false)
  }
  const handleDownloadClose = () => {
    setOpenDeck(false)
  }
  const anchorRefUser = useRef<HTMLButtonElement>(null)
  const handleCloseDisclosure = () => {
    if (!portfolioDetailData?.isDisclosureAccepted) {
      acceptPortfolioDisclosureMutation.mutate(
        portfolioDetailData?.portfolioNumber
      )
    }

    setOpenDisclosure(false)
  }

  const onDownload = () => {
    setFileType('portfolio-deck')
    setIsGettingPortfolioUri(true)
    handleDownloadClose()
  }
  const onDownloadNoChart = () => {
    setFileType('portfolio-deck-no-chart')
    setIsGettingPortfolioUri(true)
    handleDownloadClose()
  }
  const downloadFile = (fileUri: string) => {
    if (fileUri !== '') {
      window.location.href = fileUri
    }
  }

  const [openDeck, setOpenDeck] = useState(false)
  const handleDeckMenuToggle = (): void => {
    setOpenDeck((prevOpen) => !prevOpen)
  }

  const { isFetching } = useCustomQuery<any>(
    ['getPortfolioFilesLink'],
    async () =>
      getPortfolioFileUri(portfolioId, fileType).then((data: any) => {
        setIsGettingPortfolioUri(false)
        const uri = data ?? ''
        downloadFile(uri)
      }),
    { enabled: isGettingPortfolioUri, cacheTime: 0 }
  )

  const acceptPortfolioDisclosureMutation = useMutation({
    mutationFn: (portfolioNumber: number) => {
      return acceptPortfolioDisclosure(portfolioDetailData?.portfolioNumber)
    },
    onSuccess: () => {
      refetchAllPortfolioSteps(queryClient)
    },
    onError: () => {
      enqueueSnackbar('Error accepting disclosure', notifyError)
    },
  })

  const { purgePortfolio, loading: loadingPurge } = usePurgePortfolio({
    onCompleted: (loadPortfolioData) => {
      if (loadPortfolioData) {
        enqueueSnackbar(
          'The portfolio purge has been requested and will be completed in a few minutes.',
          notifySuccess
        )
        setReason((prevReason) => ({
          ...prevReason,
          value: '',
          error: '',
        }))
        history.push(getStandardUri(`${PORTFOLIO}/loaded-listed`))
        handleClose()
      }
    },
  })

  const accounts = portfolioAccounts?.accounts || []

  const handlePurgePortfolio = (): any => {
    if (!portfolioDetailData?.canDeleteAllPortfolioData && !reason.value) {
      setReason((prevReason) => ({
        ...prevReason,
        error: 'Required',
      }))
    } else if (portfolioDetailData?.id) {
      setReason((prevReason) => ({
        ...prevReason,
        error: '',
      }))
      purgePortfolio({
        variables: {
          purgePortfolioRequest: {
            portfolioId: portfolioDetailData?.id,
            preserveHeader: !!portfolioDetailData?.hasScrub,
            reason: reason.value,
          },
        },
        refetchQueries: ['GetAllPortfolioSteps'],
      })
    }
  }

  const handleReason = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setReason((prevReason) => ({
      ...prevReason,
      value,
    }))
  }

  const handleForwardFlowClick = (forwardFlowId: number | undefined): void => {
    if (forwardFlowId !== undefined) {
      history.push(getStandardUri(`${FORWARD_FLOW_DETAIL}/${forwardFlowId}`))
    }
  }

  const featureDocumentRepository = process.env.REACT_APP_HIDE_DOC_REPO

  const hideExclude =
    portfolioDetailData?.status.toLowerCase() ===
      PortfolioStatusType.PendingPSA ||
    portfolioDetailData?.status.toLowerCase() ===
      PortfolioStatusType.PendingBuyerFunding ||
    portfolioDetailData?.status.toLowerCase() ===
      PortfolioStatusType.PendingSellerFunding ||
    portfolioDetailData?.status.toLowerCase() ===
      PortfolioStatusType.PendingFinalConfirmation ||
    portfolioDetailData?.status.toLowerCase() ===
      PortfolioStatusType.AwaitingSellerFee ||
    portfolioDetailData?.status.toLowerCase() === PortfolioStatusType.Funded ||
    portfolioDetailData?.status.toLowerCase() === PortfolioStatusType.Closed ||
    portfolioDetailData?.status.toLowerCase() === PortfolioStatusType.Purged
  const [errorMessages, setErrorMessages] = useState<Messages>({})
  const [warningMessages, setWarningMessages] = useState<Messages>({})

  const fetchMessages = async (id: any, rowIndex: any, errorType: any) => {
    try {
      executeQuery({
        portfolioRowErrorId: id,
        errorType: errorType,
        rowIndex,
      })
    } catch (error) {
      console.error(error)
    }
  }
  useEffect(() => {}, [errorMessages, warningMessages])
  const { executeQuery } = useCustomLazyQuery<any>(
    ['getPortfolioErrorMsg'],
    async (params: any) => {
      const { portfolioRowErrorId, errorType, rowIndex } = params

      return getPortfolioErrorMsg(
        portfolioRowErrorId,
        errorType,
        rowIndex
      ).then((responseData: any) => {
        const key = `${portfolioRowErrorId}-${rowIndex}`
        if (errorType === 'E') {
          setErrorMessages((prev) => ({
            ...prev,
            [key + '-E']: responseData || {},
          }))
        } else {
          setWarningMessages((prev) => ({
            ...prev,
            [key + '-W']: responseData || {},
          }))
        }
      })
    },
    { enabled: false, cacheTime: 10000 }
  )
  const [lastParams, setLastParams] = useState({
    id: null,
    rowIndex: null,
    errorType: null,
  })

  const handleMouseEnter = (id: any, rowIndex: any, errorType: any) => {
    if (
      lastParams.id === id &&
      lastParams.rowIndex === rowIndex &&
      lastParams.errorType === errorType
    ) {
      return
    }
    const key = `${id}-${rowIndex}-${errorType}`
    if (!(key in errorMessages) && !(key in warningMessages)) {
      fetchMessages(id, rowIndex, errorType)
    }
    setLastParams({ id, rowIndex, errorType })
  }

  const portfolioRowColumns: any[] = [
    {
      field: 'RowIndex',
      title: 'Row #',
      width: 135,
      filter: 'numeric',
      show: isPortfolioSeller || isInternal,
      render: (props: any) => {
        const errors: string[] = []
        const errorKey = `${props.dataItem['id']}-${props.dataItem['rowIndex']}-E`
        if (errorKey in errorMessages) {
          errorMessages[errorKey]?.split('\n')?.map((errorMessage: any) => {
            errors.push(errorMessage)
            return errors
          })
        }
        const warningKey = `${props.dataItem['id']}-${props.dataItem['rowIndex']}-W`
        const warnings: string[] = []
        if (warningKey in warningMessages) {
          warningMessages[warningKey]
            ?.split('\n')
            ?.map((warningMessage: any) => {
              warnings.push(warningMessage)
              return warnings
            })
        }

        return (
          <td {...props}>
            {props.dataItem['rowIndex'] + 1}{' '}
            {props.dataItem['hasError'] && (
              <>
                <Tooltip
                  arrow
                  onMouseOver={() =>
                    handleMouseEnter(
                      props.dataItem['id'],
                      props.dataItem['rowIndex'],
                      'E'
                    )
                  }
                  title={
                    errors?.length
                      ? errors.map((message, index) => (
                          // eslint-disable-next-line react/jsx-indent
                          <TooltipTitleStep key={`${index + 1} - ${message}`}>
                            <span>{`${index + 1} - ${message}`}</span>
                          </TooltipTitleStep>
                        ))
                      : 'Loading ...'
                  }
                >
                  <ChipStatusError label="E" size="small" type="error" />
                </Tooltip>
              </>
            )}
            {props.dataItem['hasWarning'] && (
              <Tooltip
                onMouseOver={() =>
                  handleMouseEnter(
                    props.dataItem['id'],
                    props.dataItem['rowIndex'],
                    'W'
                  )
                }
                title={
                  warnings?.length
                    ? warnings.map((message, index) => (
                        // eslint-disable-next-line react/jsx-indent
                        <TooltipTitleStep key={`${index + 1} - ${message}`}>
                          <span>{`${index + 1} - ${message}`}</span>
                        </TooltipTitleStep>
                      ))
                    : 'Loading ...'
                }
              >
                <ChipStatusError label="W" size="small" type="warning" />
              </Tooltip>
            )}
          </td>
        )
      },
      orderIndex: 0,
    },
    {
      field: 'excludeAccount',
      title: 'Exclude',
      visible: 'seller-only',
      width: 130,
      orderIndex: 1,
      show: !hideExclude && tabDetailsIndex === '' && isPortfolioSeller,
      render: (props: any) => {
        if (allowRestoreAccount(props.dataItem))
          return (
            <td {...props}>
              <Button
                title="Restore"
                color="primary"
                variant="contained"
                onClick={() => {
                  onRestoreOutOfStat(props.dataItem.rowIndex)
                }}
                disabled={updatingAccountsByRow || loadingAccount}
              >
                Restore
              </Button>
            </td>
          )
        // eslint-disable-next-line jsx-a11y/control-has-associated-label
        if (!allowExcludeAccount(props.dataItem)) return <td />

        return (
          <TdNoEllipsis {...props}>
            <Checkbox
              id={`excludeCheckbox${props.dataItem['rowIndex']}`}
              disabled={disablePortfolioAccountsUI()}
              value={props.dataItem['rowIndex']}
              onChange={(event: any) => {
                if (event.target.checked)
                  setAccountsSelectedToExclusion([
                    ...accountsSelectedToExclusion,
                    props.dataItem['rowIndex'],
                  ])
                else
                  setAccountsSelectedToExclusion(
                    accountsSelectedToExclusion.filter(
                      (rowIndex) => rowIndex !== props.dataItem['rowIndex']
                    )
                  )
              }}
              checked={handleExclusionCheck(props)}
            />
          </TdNoEllipsis>
        )
      },
    },
    {
      field: 'restoreAccount',
      title: 'Restore',
      width: 120,
      show: tabDetailsIndex === 'excluded' && isPortfolioSeller,
      render: (props: any) => {
        // eslint-disable-next-line jsx-a11y/control-has-associated-label
        if (!allowRestoreAccount(props.dataItem)) return <td />

        return (
          <TdNoEllipsis {...props}>
            <Checkbox
              value={props.dataItem['rowIndex']}
              onChange={(event: any) => {
                if (event.target.checked)
                  setAccountsSelectedToRestore([
                    ...accountsSelectedToRestore,
                    props.dataItem['rowIndex'],
                  ])
                else
                  setAccountsSelectedToRestore(
                    accountsSelectedToRestore.filter(
                      (rowIndex) => rowIndex !== props.dataItem['rowIndex']
                    )
                  )
              }}
              checked={handleRestoreCheck(props)}
            />
          </TdNoEllipsis>
        )
      },
      orderIndex: 2,
    },
    {
      field: 'removedComment',
      title: 'Removed Comment',
      width: 200,
      orderIndex: 2,
      show: isPortfolioSeller || isInternal,
    },
    {
      field: 'lenderLoanId',
      title: 'Loan ID',
      width: 150,
      show: true,
      render: (props: any) => {
        return (
          <td {...props}>
            {canOpenAccount ? (
              <Button
                component={Link}
                to={getStandardUri(
                  `${ACCOUNT_DETAIL}/${props.dataItem.portfolioRow}`
                )}
              >
                {' '}
                {props.dataItem.lenderLoanId}
              </Button>
            ) : (
              <>{props.dataItem.lenderLoanId}</>
            )}
          </td>
        )
      },
      orderIndex: 2,
    },
    {
      field: 'bKCaseNumber',
      title: 'Case #',
      width: 150,
      orderIndex: 2,
      show: isBk && isPortfolioSeller,
    },
    {
      field: 'bKChapter',
      title: 'Chapter',
      width: 150,
      orderIndex: 2,
      show: isBk,
    },
    {
      field: 'bKFileDate',
      title: 'File Date',
      width: 200,
      filter: 'date',
      render: renderDate,
      orderIndex: 2,
      show: isBk,
    },
    {
      field: 'bKBarDate',
      title: 'Bar Date',
      render: renderDate,
      filter: 'date',
      width: 120,
      orderIndex: 2,
      show: isBk,
    },
    {
      field: 'product',
      title: 'Product',
      width: 120,
      orderIndex: 2,
      show: true,
    },
    {
      field: 'originalLoanAmount',
      title: 'Orig. Amt.',
      tooltip: 'Origination Amount',
      render: renderCurrency,
      alignRight: true,
      filter: 'numeric',
      width: 120,
      orderIndex: 2,
      show: true,
    },
    {
      field: 'principalBalance',
      title: 'Principal',
      render: renderCurrency,
      alignRight: true,
      filter: 'numeric',
      width: 120,
      orderIndex: 2,
      show: true,
    },
    {
      field: 'interestBalance',
      title: 'Interest',
      render: renderCurrency,
      alignRight: true,
      filter: 'numeric',
      width: 120,
      orderIndex: 2,
      show: true,
    },
    {
      field: 'otherFeesBalance',
      title: 'Fees',
      render: renderCurrency,
      alignRight: true,
      filter: 'numeric',
      width: 100,
      orderIndex: 2,
      show: true,
    },
    {
      field: 'totalBalance',
      title: 'Total Balance',
      render: renderCurrency,
      alignRight: true,
      filterable: false,
      filter: 'numeric',
      width: 120,
      orderIndex: 2,
      show: true,
    },
    {
      field: 'chargeOffBalance',
      title: 'Charge-Off Balance',
      filterable: false,
      alignRight: true,
      render: renderCurrency,
      filter: 'numeric',
      width: 140,
      orderIndex: 2,
      show: true,
    },
    {
      field: 'totalPaymentsSinceChargeOff',
      title: 'Total Payments Since Charge-Off',
      filterable: false,
      alignRight: true,
      width: 150,
      render: renderNumber,
      filter: 'numeric',
      orderIndex: 2,
      show: true,
    },
    {
      field: 'originationDate',
      title: 'Orig. Date',
      tooltip: 'Origination Date',
      render: renderDate,
      filter: 'date',
      width: 120,
      orderIndex: 2,
      show: true,
    },
    {
      field: 'defaultDate',
      title: 'Def. Date',
      tooltip: 'Default Date',
      render: renderDate,
      filter: 'date',
      width: 120,
      orderIndex: 2,
      show: true,
    },
    {
      field: 'lastPaymentAmount',
      title: 'Amount',
      render: renderCurrency,
      alignRight: true,
      filter: 'numeric',
      width: 120,
      orderIndex: 2,
      show: true,
    },
    {
      field: 'lastPaymentDate',
      title: 'Last Payment Date',
      render: renderDate,
      width: 140,
      filter: 'date',
      orderIndex: 2,
      show: true,
    },
    {
      field: 'writeOffDate',
      title: 'C/O Date',
      tooltip: 'Charge-Off Date',
      render: renderDate,
      width: 120,
      filter: 'date',
      orderIndex: 2,
      show: true,
    },
    {
      field: 'lender',
      title: 'Lender',
      width: 200,
      orderIndex: 2,
      show: true,
    },
    {
      field: 'stateCode',
      title: isUK ? 'County' : isCanada ? 'Province' : 'State',
      width: 100,
      orderIndex: 2,
      show: true,
    },
  ]

  const disablePortfolioAccountsUI = (): boolean => {
    if (loadingAccount || updatingAccountsByRow || excludingAccountsOutOfStat)
      return true

    return false
  }

  const getManageAccountsButtonText = (): string => {
    if (tabDetailsIndex === '') {
      if (accountsSelectedToExclusion.length > 0) {
        return `Exclude ${accountsSelectedToExclusion.length} account${
          accountsSelectedToExclusion.length > 1 ? 's' : ''
        }`
      }
      return '0 accounts selected'
    }

    if (accountsSelectedToRestore.length > 0) {
      return `Restore ${accountsSelectedToRestore.length} account${
        accountsSelectedToRestore.length > 1 ? 's' : ''
      }`
    }

    return '0 accounts selected'
  }

  const allowExcludeAccount = (dataItem: any) => {
    if (!dataItem.removedUtcDate && !dataItem.hasError) return true

    return false
  }

  const allowRestoreAccount = (dataItem: any) => {
    // TODO: Add permissions, only admins can restore accounts
    // v.canExcludeOrRestoreAccounts = (selectedBusiness &&
    //     (selectedBusiness.PermissionLevel.includes(PermissionLevel_1.PermissionLevel.User) ||
    //         selectedBusiness.PermissionLevel.includes(PermissionLevel_1.PermissionLevel.Admin)));
    if (
      dataItem.removedUtcDate &&
      !dataItem.hasError &&
      !dataItem.excludedByBuyer &&
      !dataItem.excludedByScrub &&
      !dataItem.excludedByValidation &&
      !dataItem.excludedPriorScrub
    )
      return true

    return false
  }

  const disableManageAccountsButton = (): boolean => {
    if (tabDetailsIndex === '') {
      if (accountsSelectedToExclusion.length === 0 || !removeAccountsReason) {
        return true
      }

      return false
    }

    if (tabDetailsIndex === 'excluded') {
      if (accountsSelectedToRestore.length === 0) return true

      return false
    }

    return true
  }

  const getPrevNextPortfolioNumber = (index: number): string => {
    const l = portfolioGridData?.data?.length
    if (l === undefined || l === 0) {
      return ''
    }

    if (index < 0 || index >= l) {
      return ''
    }

    return String(portfolioGridData?.data[index]?.portfolioNumber)
  }

  const getCurrentIndex = (): number => {
    if (portfolioGridData)
      return portfolioGridData?.data?.findIndex(
        (p: any) => p.portfolioId === portfolioId
      )
    return -100
  }

  const getStatus = (str: string | undefined): string => {
    let s = str?.toLowerCase()
    if (s === PortfolioStatusType.AwaitingSellerFee && isPortfolioBuyer)
      s = PortfolioStatusType.Funded

    s = capitalize(s).replace('Psa', 'PSA')
    return s
  }

  const openScrubRemoved = () => {
    setTabDetailsIndex('scrubRemoved')
    ResetAccountsGrid()
    handleSetOpenAccounts(true)
  }

  const openErrorsWarnings = (type: string) => {
    setTabDetailsIndex(type)
    ResetAccountsGrid()
    handleSetOpenAccounts(true)
  }

  const showScrubAwaitingSellerComponent =
    userPermissions.type === 'Buyer' || isInternal

  const showScrubComponent =
    portfolioDetailData &&
    hasAccounts &&
    (userPermissions.type === 'Seller' ||
      (userPermissions.type === BUYER &&
        userPermissions.isReseller &&
        userType?.userType === SELLER)) &&
    ability.can(
      PermissionCodeAccess.MarketPlace_Basic_SubmitPortfolioScrub,
      'any'
    ) &&
    ((portfolioDetailData?.status.toLowerCase() ===
      PortfolioStatusType.ReadyForReview &&
      ((process.env.REACT_APP_CUSTOM_NODE_ENV !== 'production' &&
        portfolioDetailData?.portfolioCountry?.toUpperCase() === 'UK') ||
        portfolioDetailData?.portfolioCountry?.toUpperCase() === 'US') &&
      (!portfolioDetailData?.hasScrub ||
        (portfolioDetailData?.hasScrub &&
          portfolioDetailData?.allowSecondScrub))) ||
      (portfolioDetailData?.status.toLowerCase() ===
        PortfolioStatusType.Awarded &&
        ((process.env.REACT_APP_CUSTOM_NODE_ENV !== 'production' &&
          portfolioDetailData?.portfolioCountry?.toUpperCase() === 'UK') ||
          portfolioDetailData?.portfolioCountry?.toUpperCase() === 'US') &&
        portfolioDetailData?.portfolioTypeId !== 2 &&
        (!portfolioDetailData?.hasScrub ||
          (portfolioDetailData?.hasScrub &&
            portfolioDetailData?.allowSecondScrub))))

  const pStatus = portfolioDetailData?.status?.toLowerCase() || ''

  const canOpenAccount =
    isInternal ||
    isPortfolioSeller ||
    (userType?.userType === BUYER &&
      [
        'pending seller funding',
        'pending final confirmation',
        'awaiting seller fee',
        'closed',
        'funded',
      ].includes(pStatus))

  const IsBuyerStatusAllowedToPerformOperation = () => {
    if (isInternal) return false

    const clientStatusesAllowed = ['approved', 'pre-approved']
    const businessStatusesAllowed = [
      'approved',
      'application in process',
      'pre-approved',
    ]

    const clientStatus = profileClient.Status.toLowerCase()

    const isClientAllowed = clientStatusesAllowed.includes(clientStatus)
    const isBusinessAllowed = profileBusiness.some(
      (x) =>
        businessStatusesAllowed.includes(x.Status.toLowerCase()) &&
        x.Type.toLowerCase() === 'buyer'
    )

    if (isClientAllowed && isBusinessAllowed) return true

    return false
  }

  const showSellerReviewForwardFlow =
    portfolioId &&
    hasAccounts &&
    pStatus === PortfolioStatusType.ReadyForReview &&
    userPermissions.type === 'Seller' &&
    (portfolioDetailData?.portfolioCountry === 'CA' ||
      (portfolioDetailData?.portfolioCountry === 'UK' &&
        process.env.REACT_APP_CUSTOM_NODE_ENV === 'production')) &&
    portfolioDetailData?.forwardFlowAgreementId &&
    ability.can(PermissionCodeAccess.MarketPlace_Basic_UploadPortfolio, 'any')

  const showSellerReview =
    portfolioId &&
    hasAccounts &&
    pStatus === PortfolioStatusType.ReadyForReview &&
    (userPermissions.type === 'Seller' ||
      (userPermissions.type === BUYER &&
        userPermissions.isReseller &&
        userType?.userType === SELLER)) &&
    (portfolioDetailData?.hasScrub ||
      portfolioDetailData?.portfolioCountry === 'CA' ||
      (portfolioDetailData?.portfolioCountry === 'UK' &&
        process.env.REACT_APP_CUSTOM_NODE_ENV === 'production')) &&
    !portfolioDetailData?.forwardFlowAgreementId

  const showSellerBkFFAwardedAction =
    portfolioId &&
    hasAccounts &&
    portfolioDetailData &&
    pStatus === PortfolioStatusType.Awarded &&
    portfolioDetailData?.portfolioTypeId === 2 &&
    isPortfolioSeller &&
    userPermissions.type === 'Seller'

  const showBuyerFundingInformation =
    portfolioId &&
    hasAccounts &&
    portfolioDetailData &&
    [
      PortfolioStatusType.PendingPSA.toString(),
      PortfolioStatusType.PendingBuyerFunding.toString(),
    ].includes(pStatus) &&
    userPermissions.type === 'Buyer' &&
    ability.can(
      PermissionCodeAccess.MarketPlace_Basic_SubmitFundingInformation,
      'any'
    )

  const showConfirmSellerFunding =
    portfolioId &&
    hasAccounts &&
    portfolioDetailData &&
    [
      PortfolioStatusType.PendingPSA.toString(),
      PortfolioStatusType.PendingSellerFunding.toString(),
      PortfolioStatusType.PendingBuyerFunding.toString(),
    ].includes(portfolioDetailData?.status.toLowerCase()) &&
    PortfolioStatusType.PendingSellerFunding &&
    userPermissions.type === 'Seller'

  const showFinalFundingConfirmation =
    portfolioId &&
    hasAccounts &&
    portfolioDetailData &&
    [
      PortfolioStatusType.PendingPSA.toString(),
      PortfolioStatusType.PendingSellerFunding.toString(),
      PortfolioStatusType.PendingBuyerFunding.toString(),
      PortfolioStatusType.PendingFinalConfirmation.toString(),
    ].includes(pStatus) &&
    userPermissions.type === 'internal'

  const showInternalSellerSurvey =
    portfolioId &&
    portfolioDetailData &&
    [
      PortfolioStatusType.ReadyForReview.toString(),
      PortfolioStatusType.Listed.toString(),
    ].includes(pStatus) &&
    userPermissions.type === 'internal' &&
    ability.can(
      PermissionCodeAccess.MarketPlace_Internal_UpdatePortfolioSellerSurvey,
      'any'
    )

  const showUpdateCloseFundingDate =
    portfolioId &&
    hasAccounts &&
    portfolioDetailData &&
    pStatus === PortfolioStatusType.Awarded &&
    portfolioDetailData?.forwardFlowAgreementId &&
    portfolioDetailData?.portfolioTypeId !== 2 &&
    ability.can(
      PermissionCodeAccess.MarketPlace_ForwardFlow_ConfirmForwardFlow,
      'any'
    )

  const showConfirmSellerFee =
    portfolioId &&
    hasAccounts &&
    portfolioDetailData &&
    [PortfolioStatusType.AwaitingSellerFee.toString()].includes(pStatus) &&
    userPermissions.type === 'internal'

  const showFundingInformation =
    portfolioId &&
    hasAccounts &&
    (([
      PortfolioStatusType.PendingFinalConfirmation.toString(),
      PortfolioStatusType.PendingSellerFunding.toString(),
      PortfolioStatusType.AwaitingSellerFee.toString(),
      PortfolioStatusType.Funded.toString(),
    ].includes(pStatus) &&
      userPermissions.type === 'Buyer') ||
      ([
        PortfolioStatusType.PendingFinalConfirmation.toString(),
        PortfolioStatusType.AwaitingSellerFee.toString(),
        PortfolioStatusType.Funded.toString(),
      ].includes(pStatus) &&
        userPermissions.type === 'Seller') ||
      ([PortfolioStatusType.Funded.toString()].includes(pStatus) &&
        userPermissions.type === 'internal'))

  const showListPortfolio =
    portfolioId &&
    hasAccounts &&
    portfolioDetailData &&
    pStatus === PortfolioStatusType.ReadyForReview &&
    (userPermissions.type === 'Seller' ||
      (userPermissions.type === BUYER &&
        userPermissions.isReseller &&
        userType?.userType === SELLER)) &&
    ability.can(PermissionCodeAccess.MarketPlace_Basic_ListPortfolio, 'any')

  const showBuyerPlaceBid =
    IsBuyerStatusAllowedToPerformOperation() &&
    portfolioId &&
    hasAccounts &&
    portfolioDetailData &&
    (pStatus === PortfolioStatusType.Listed ||
      (pStatus === PortfolioStatusType.Awarded &&
        !portfolioDetailData?.forwardFlowAgreementId) ||
      (pStatus === PortfolioStatusType.Awarded &&
        portfolioDetailData?.forwardFlowAgreementId &&
        portfolioDetailData?.portfolioTypeId === 2)) &&
    !isPortfolioSeller &&
    ability.can(PermissionCodeAccess.MarketPlace_Basic_PlaceBid, 'any')

  const isAuctionClosed =
    !!portfolioDetailData?.bidCloseUtc &&
    isBefore(
      Date.parse(portfolioDetailData?.bidCloseUtc?.toString() || ''),
      Date.now()
    )

  const showSellerBidSummary =
    portfolioId &&
    hasAccounts &&
    ((!isAuctionClosed &&
      pStatus === PortfolioStatusType.Listed &&
      isPortfolioSeller &&
      isUK) ||
      (!PortfolioStatus.getStatusesBeforeListedToLower().includes(
        pStatus.toLowerCase()
      ) &&
        userPermissions.type === 'internal'))

  const showBidReview =
    portfolioId &&
    hasAccounts &&
    ((pStatus === PortfolioStatusType.Listed && isPortfolioSeller) ||
      ([
        PortfolioStatusType.Listed.toString(),
        PortfolioStatusType.Awarded.toString(),
        PortfolioStatusType.AwaitingSellerFee.toString(),
        PortfolioStatusType.Funded.toString(),
        PortfolioStatusType.PendingSellerFunding.toString(),
        PortfolioStatusType.PendingBuyerFunding.toString(),
        PortfolioStatusType.PendingPSA.toString(),
        PortfolioStatusType.PendingFinalConfirmation.toString(),
      ].includes(pStatus) &&
        userPermissions.type === 'internal') ||
      userPermissions.type === 'Seller') &&
    isAuctionClosed

  const showStatusPortfolio =
    (portfolioId && pStatus === PortfolioStatusType.WaitingForScrubResults) ||
    pStatus === PortfolioStatusType.SubmittingForScrubResults

  const showBuyerAccess =
    portfolioId &&
    hasAccounts &&
    portfolioDetailData &&
    [
      PortfolioStatusType.ReadyForReview.toString(),
      PortfolioStatusType.WaitingForScrubResults.toString(),
      PortfolioStatusType.SubmittingForScrubResults.toString(),
      PortfolioStatusType.Listed.toString(),
    ].includes(pStatus) &&
    !isAuctionClosed &&
    userPermissions.type === 'internal' &&
    (ability.can(
      PermissionCodeAccess.Marketplace_Internal_Buyer_Portfolio_Access_View,
      'any'
    ) ||
      ability.can(
        PermissionCodeAccess.Marketplace_Internal_Buyer_Portfolio_Access_Update,
        'any'
      ))

  const showUnmaskData =
    IsBuyerStatusAllowedToPerformOperation() &&
    portfolioId &&
    hasAccounts &&
    portfolioDetailData?.allowAdditionalUnmaskedData &&
    !portfolioDetailData?.forwardFlowAgreementId &&
    !isPortfolioSeller &&
    [PortfolioStatusType.Listed.toString()].includes(pStatus) &&
    ability.can(PermissionCodeAccess.MarketPlace_Basic_RequestUnmaskData, 'any')

  const showApproveUnmaskData =
    portfolioId &&
    hasAccounts &&
    portfolioDetailData?.allowAdditionalUnmaskedData &&
    [
      PortfolioStatusType.Listed.toString(),
      PortfolioStatusType.Awarded.toString(),
      PortfolioStatusType.PendingPSA.toString(),
      PortfolioStatusType.PendingBuyerFunding.toString(),
      PortfolioStatusType.PendingSellerFunding.toString(),
      PortfolioStatusType.AwaitingSellerFee.toString(),
      PortfolioStatusType.PendingFinalConfirmation.toString(),
      PortfolioStatusType.Funded.toString(),
    ].includes(pStatus) &&
    (userPermissions.type === 'internal' ||
      (((userPermissions.type === BUYER &&
        userPermissions.isReseller &&
        userType?.userType === SELLER) ||
        userPermissions.type === 'Seller') &&
        ability.can(
          PermissionCodeAccess.MarketPlace_Basic_ApproveUnmaskData,
          'any'
        )))

  const showScoringRequest =
    portfolioId &&
    hasAccounts &&
    userPermissions.type === 'Buyer' &&
    pStatus === PortfolioStatusType.Listed &&
    portfolioDetailData?.portfolioTypeId !== 2 &&
    portfolioDetailData?.portfolioCountry === 'US' &&
    !isAuctionClosed &&
    !isPortfolioSeller &&
    ability.can(PermissionCodeAccess.MarketPlace_Scoring_RequestScoring, 'any')

  const showPortfolioHistory =
    portfolioId && portfolioDetailData && userPermissions.type === 'internal'

  const showPurchaseSaleAgreement =
    portfolioId &&
    hasAccounts &&
    portfolioDetailData &&
    pStatus === PortfolioStatusType.PendingPSA &&
    ability.can(
      PermissionCodeAccess.MarketPlace_Workflows_CompletePSAWorkflow,
      'any'
    )

  const showAdminRegenerateFile =
    portfolioId &&
    portfolioDetailData &&
    userPermissions.type === 'internal' &&
    ability.can(
      PermissionCodeAccess.MarketPlace_Internal_ReGenerateFiles,
      'any'
    ) &&
    pStatus !== PortfolioStatusType.Funded

  const showAdminCloseAuction =
    portfolioId &&
    portfolioDetailData &&
    pStatus === PortfolioStatusType.Listed &&
    !isAuctionClosed &&
    ability.can(
      PermissionCodeAccess.MarketPlace_Internal_OpenClosePortfolioBidWindow,
      'any'
    )

  const modifyListPortfolioImmediately =
    portfolioId &&
    portfolioDetailData &&
    pStatus === PortfolioStatusType.ReadyForReview &&
    portfolioDetailData?.listDateUtc &&
    !isAuctionClosed &&
    (ability.can(
      PermissionCodeAccess.MarketPlace_Internal_OpenClosePortfolioBidWindow,
      'any'
    ) ||
      ability.can(
        PermissionCodeAccess.Marketplace_Internal_Approve_Listing_Portfolio,
        'any'
      ))
  const showListPortfolioImmediately =
    (userPermissions.type === 'internal' ||
      userPermissions.type === 'Seller') &&
    portfolioId &&
    portfolioDetailData &&
    portfolioDetailData?.listDateUtc &&
    (ability.can(
      PermissionCodeAccess.MarketPlace_Internal_OpenClosePortfolioBidWindow,
      'any'
    ) ||
      ability.can(
        PermissionCodeAccess.Marketplace_Internal_Approve_Listing_Portfolio,
        'any'
      ))

  const showAdminUpdateBuyerFee =
    portfolioId &&
    portfolioDetailData &&
    userPermissions.type === 'internal' &&
    [
      PortfolioStatusType.Awarded.toString(),
      PortfolioStatusType.PendingPSA.toString(),
      PortfolioStatusType.PendingBuyerFunding.toString(),
      PortfolioStatusType.PendingSellerFunding.toString(),
    ].includes(pStatus) &&
    ability.can(
      PermissionCodeAccess.MarketPlace_Internal_UpdatePortfolioFees,
      'any'
    )

  const showAdminUpdateSellerFee =
    portfolioId &&
    portfolioDetailData &&
    userPermissions.type === 'internal' &&
    [
      PortfolioStatusType.Awarded.toString(),
      PortfolioStatusType.PendingPSA.toString(),
      PortfolioStatusType.PendingBuyerFunding.toString(),
      PortfolioStatusType.PendingSellerFunding.toString(),
      PortfolioStatusType.AwaitingSellerFee.toString(),
      PortfolioStatusType.PendingFinalConfirmation.toString(),
    ].includes(pStatus) &&
    ability.can(
      PermissionCodeAccess.MarketPlace_Internal_UpdatePortfolioFees,
      'any'
    )

  const showAdminSaveBid =
    portfolioId &&
    portfolioDetailData &&
    userPermissions.type === 'internal' &&
    [
      PortfolioStatusType.Listed.toString(),
      PortfolioStatusType.Awarded.toString(),
      PortfolioStatusType.PendingPSA.toString(),
      PortfolioStatusType.PendingBuyerFunding.toString(),
      PortfolioStatusType.PendingSellerFunding.toString(),
      PortfolioStatusType.AwaitingSellerFee.toString(),
      PortfolioStatusType.PendingFinalConfirmation.toString(),
    ].includes(pStatus) &&
    ability.can(PermissionCodeAccess.MarketPlace_Internal_UpdateBids, 'any')

  const showAdminDisclosure =
    portfolioId &&
    portfolioDetailData &&
    ((userPermissions.type === 'internal' &&
      ability.can(
        PermissionCodeAccess.MarketPlace_Internal_UpdatePortfolioDisclosure,
        'any'
      ) &&
      pStatus !== PortfolioStatusType.Funded) ||
      (isPortfolioSeller &&
        PortfolioStatus.getStatusesBeforeListedToLower().includes(
          pStatus.toLowerCase()
        )))

  const showAdminAddDownload =
    ((portfolioId &&
      [
        PortfolioStatusType.AwaitingSellerFee.toString(),
        PortfolioStatusType.Funded.toString(),
      ].includes(pStatus)) ||
      ([
        PortfolioStatusType.PendingPSA.toString(),
        PortfolioStatusType.PendingBuyerFunding.toString(),
        PortfolioStatusType.PendingSellerFunding.toString(),
        PortfolioStatusType.PendingFinalConfirmation.toString(),
      ].includes(pStatus) &&
        portfolioDetailData?.hasSCRACert)) &&
    userPermissions.type === 'internal' &&
    ability.can(PermissionCodeAccess.Marketplace_Internal_AddDownload, 'any')

  const showAdminChangeBuyer =
    portfolioId &&
    portfolioDetailData?.buyerId &&
    !portfolioDetailData?.forwardFlowAgreementId &&
    userPermissions.type === 'internal' &&
    [
      PortfolioStatusType.Awarded.toString(),
      PortfolioStatusType.PendingPSA.toString(),
      PortfolioStatusType.PendingBuyerFunding.toString(),
    ].includes(pStatus)

  const showInvoices =
    portfolioId &&
    userPermissions.type === 'internal' &&
    [
      PortfolioStatusType.PendingPSA.toString(),
      PortfolioStatusType.PendingBuyerFunding.toString(),
      PortfolioStatusType.PendingSellerFunding.toString(),
      PortfolioStatusType.AwaitingSellerFee.toString(),
      PortfolioStatusType.PendingFinalConfirmation.toString(),
    ].includes(pStatus)

  const showPortfolioMediaFilesToSeller =
    ability.can(
      PermissionCodeAccess.MarketPlace_Basic_Upload_Media_Files,
      'any'
    ) &&
    [
      PortfolioStatusType.Listed.toString(),
      PortfolioStatusType.PendingBuyerFunding.toString(),
      PortfolioStatusType.PendingSellerFunding.toString(),
      PortfolioStatusType.AwaitingSellerFee.toString(),
      PortfolioStatusType.Funded.toString(),
      PortfolioStatusType.Closed.toString(),
    ].includes(pStatus) &&
    isPortfolioSeller

  const showPortfolioMediaFilesToBuyer =
    ability.can(
      PermissionCodeAccess.MarketPlace_Basic_Download_Media_Files,
      'any'
    ) &&
    [
      PortfolioStatusType.PendingBuyerFunding.toString(),
      PortfolioStatusType.PendingSellerFunding.toString(),
      PortfolioStatusType.AwaitingSellerFee.toString(),
      PortfolioStatusType.Funded.toString(),
      PortfolioStatusType.Closed.toString(),
    ].includes(pStatus) &&
    isPortfolioBuyer

  const {
    data: portfolioMediaFiles,
    isFetching: loadingPortfolioMediaFiles,
    refetch: refetchMediaFileLinks,
  } = useCustomQuery<PortfolioMediaFileLink[]>(
    ['getPortfolioMediaFilesLink', portfolioId],
    async () => getPortfolioMediaFilesLink(portfolioId),
    {
      enabled:
        showPortfolioMediaFilesToSeller ||
        showPortfolioMediaFilesToBuyer ||
        isInternal,
      cacheTime: 0,
    }
  )

  const canUploadMediaFile = ability.can(
    PermissionCodeAccess.MarketPlace_Basic_Upload_Media_Files,
    'any'
  )
  const onExcludeOutOfStat = () => {
    setExcludingAccountsOutOfStat(true)
    excludeOutOfStatAccountsEvent.mutate(portfolioId)
  }

  const onRestoreOutOfStat = (portifolioRowId: string) => {
    setManageAccountsByRowRequest({
      command: 'restore',
      portfolioId,
      portfolioRowIndexes: [Number(portifolioRowId)],
      removeComment: removeAccountsReason,
    })
  }

  const noScrubText = () => {
    let name = 'Scrubbed No Sale'
    if (portfolioAccounts?.totalOfAccountsScrubNoSale) {
      name += `  (${
        portfolioAccounts?.totalOfAccountsScrubNoSale
      } -  ${numberToPercentage(
        portfolioAccounts?.percentOfAccountsScrubNoSale
      )})   `
    }
    return name
  }
  const renderNoScrubbedTab = () => {
    if (userPermissions.type === 'Buyer') {
      return false
    }

    if (
      portfolioDetailData?.portfolioTypeId === 2 &&
      portfolioDetailData?.hasScrub
    )
      return true

    return false
  }
  const RenderButtonDelete = (canPurge: boolean) => {
    if (canPurge) {
      return (
        <Box display="flex" style={{ verticalAlign: 'middle' }}>
          <IconButton
            data-cy="delete-portfolio-button"
            size="medium"
            color="secondary"
            onClick={() => {
              setOpenDeleteCofirm(true)
            }}
          >
            <Icon name="Delete" fontSize="medium" />
          </IconButton>
        </Box>
      )
    }
    return null
  }

  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image" />
      <div className="k-loading-color" />
    </div>
  )

  const ResetAccountsGrid = () => {
    // Resetting grid and selections
    // if the portfolio accounts tab is changed
    setGridState({ skip: 0, take: 25, filter: undefined, sort: undefined })
    setAccountsSelectedToExclusion([])
    setAccountsSelectedToRestore([])
  }

  const handleUploadFile = async () => {
    const parsedFile = await fParseXlsxFile(uploadFile)

    if (parsedFile.errorMessage) {
      enqueueSnackbar(parsedFile.errorMessage, notifyError)
      return
    }

    if (checkAndNotifyIfMissing(parsedFile, 'Reason')) {
      return
    }

    if (checkAndNotifyIfMissing(parsedFile, 'LenderLoanID')) {
      return
    }

    if (checkAndNotifyIfMissing(parsedFile, 'Lender')) {
      return
    }

    setManageAccountsByLenderRequest({
      portfolioId: portfolioDetailData?.id,
      command: 'exclude',
      itemRequest: parsedFile.data.map((row) => ({
        lenderLoanId: row.LenderLoanID,
        lender: row.Lender,
        reason: row.Reason,
      })),
    })
  }
  const excludeOutOfStatAccountsEvent = useMutation({
    mutationFn: (request: string) => excludeOutOfStatAccounts(request),
    onSuccess: (data: any) => {
      enqueueSnackbar(data, notifySuccess)
      setAccountsSelectedToExclusion([])
      setAccountsSelectedToRestore([])
      setOpenExcludeOutOfStatModal(false)
      setExcludingAccountsOutOfStat(false)
      reactQueryClient.refetchQueries({ queryKey: ['getPortfolioAccounts'] })
    },
    onError: () => {
      enqueueSnackbar('Error on excluding accounts', notifyError)
      setExcludingAccountsOutOfStat(false)
    },
  })
  if (portfolioDetailError) {
    const path = userType?.userType === BUYER ? 'auction-open' : 'loaded-listed'
    return <Redirect to={getStandardUri(`${PORTFOLIO}/${path}`)} />
  }

  return (
    <SectionColumn>
      <Content>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header
              title="Portfolio Detail"
              actions={
                <>
                  {portfolioDetailData?.bidCloseUtc && (
                    <DisplayAuction
                      deadline={portfolioDetailData?.bidCloseUtc}
                      loading={loading}
                      screen="small"
                    />
                  )}
                </>
              }
            />
          </Grid>
          {!portfolioDetailData ? (
            <Grid item xs={12}>
              <Skeleton variant="rectangular" width="100%" height={50} />
            </Grid>
          ) : (
            <Grid item xs={12} style={{ paddingTop: 0 }}>
              <Box display="flex" justifyContent="space-between">
                <Box display="flex" alignItems="center">
                  {cardName ? (
                    <PortfolioNavigation
                      data-cy="portfolio-number"
                      disabled={portfolioGridLoading}
                      length={portfolioGridData?.data?.length ?? 0}
                      currentIndex={getCurrentIndex()}
                      label={portfolioDetailData?.portfolioNumber}
                      status={`${getStatus(portfolioDetailData?.status)}  |  ${
                        portfolioDetailData?.portfolioTypeName
                      } `}
                      next={getPrevNextPortfolioNumber(getCurrentIndex() + 1)}
                      prev={getPrevNextPortfolioNumber(getCurrentIndex() - 1)}
                      onNext={() => {
                        if (portfolioGridData?.data?.length) {
                          const length = portfolioGridData.data?.length - 1
                          const current = getCurrentIndex() + 1
                          if (current <= length) {
                            const newPortfolioId =
                              portfolioGridData.data[current].portfolioId
                            history.push(
                              getStandardUri(
                                subFilter
                                  ? `${PORTFOLIO_DETAIL}/${newPortfolioId}/${cardName}/${subFilter}`
                                  : `${PORTFOLIO_DETAIL}/${newPortfolioId}/${cardName}`
                              )
                            )
                          }
                        }
                      }}
                      onPrev={() => {
                        if (portfolioGridData?.data?.length) {
                          const current = getCurrentIndex() - 1
                          if (current >= 0) {
                            const newPortfolioId =
                              portfolioGridData.data[current].portfolioId
                            history.push(
                              getStandardUri(
                                `${PORTFOLIO_DETAIL}/${newPortfolioId}/${cardName}`
                              )
                            )
                          }
                        }
                      }}
                    />
                  ) : (
                    <Box display="flex" alignItems="center" pr={4}>
                      <Tooltip
                        title={`${getStatus(portfolioDetailData?.status)} | ${
                          portfolioDetailData?.portfolioTypeName
                        }`}
                      >
                        <Typography color="secondary">
                          {portfolioDetailData?.portfolioNumber}
                        </Typography>
                      </Tooltip>
                    </Box>
                  )}
                  {isInternal && (
                    <Can
                      do={
                        PermissionCodeAccess.MarketPlace_Internal_DeletePortfolio
                      }
                      on="any"
                    >
                      {RenderButtonDelete(portfolioDetailData?.canPurge)}
                    </Can>
                  )}
                  {!isInternal && (
                    <Can
                      do={
                        PermissionCodeAccess.MarketPlace_Basic_DeletePortfolio
                      }
                      on="any"
                    >
                      {RenderButtonDelete(portfolioDetailData?.canPurge)}
                    </Can>
                  )}
                  {isCanada && (
                    <Box pr={4}>
                      <Canada width={21} height={15} />
                    </Box>
                  )}
                  {(isInternal ||
                    (ability.can(
                      PermissionCodeAccess.MarketPlace_Basic_AccessPortfolioDeck,
                      'any'
                    ) &&
                      !!portfolioDetailData?.numberOfAccounts)) && (
                    // eslint-disable-next-line react/jsx-indent
                    <Box display="flex" style={{ verticalAlign: 'middle' }}>
                      <Tooltip title="Portfolio Deck">
                        <IconButton
                          ref={anchorRefUser}
                          aria-controls={
                            openDeck ? 'menu-list-grow' : undefined
                          }
                          aria-haspopup="true"
                          data-cy="portfolio-deck-menu"
                          color="secondary"
                          onClick={handleDeckMenuToggle}
                        >
                          <Icon name="GetApp" fontSize="medium" />
                        </IconButton>
                      </Tooltip>
                      <Menu
                        id="deck-menu"
                        aria-labelledby="deck-menu"
                        anchorEl={anchorRefUser.current}
                        open={openDeck}
                        onClose={handleDownloadClose}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                      >
                        <MenuItem disabled={isFetching} onClick={onDownload}>
                          Chart
                        </MenuItem>
                        <MenuItem
                          disabled={isFetching}
                          onClick={onDownloadNoChart}
                        >
                          No Chart
                        </MenuItem>
                      </Menu>
                    </Box>
                  )}
                  {(!portfolioDetailData?.numberOfAccounts ||
                    portfolioDetailData?.numberOfAccounts === 0) && (
                    <Box display="flex" style={{ verticalAlign: 'middle' }}>
                      <Tooltip title="Portfolio has no valid account">
                        <Icon
                          name="Error"
                          fontSize="medium"
                          style={{ color: 'red' }}
                        />
                      </Tooltip>
                    </Box>
                  )}
                  {portfolioAccounts && (
                    <Box display="flex" style={{ verticalAlign: 'middle' }}>
                      <Tooltip title="Accounts">
                        <IconButton
                          color="secondary"
                          onClick={() => {
                            handleSetOpenAccounts(true)
                          }}
                        >
                          <Icon name="AccountBox" fontSize="medium" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )}
                  {!!portfolioDetailData?.numberOfAccounts && (
                    <Box display="flex" style={{ verticalAlign: 'middle' }}>
                      <Tooltip title="Analysis">
                        <IconButton
                          color="secondary"
                          onClick={() => {
                            setOpenAnalysis(true)
                          }}
                        >
                          <Icon name="PieChart" fontSize="medium" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )}
                  {!featureDocumentRepository &&
                    (userType?.userType === BUYER ||
                      userType?.userType === INTERNAL) && (
                      <Box display="flex" alignItems="center">
                        <Tooltip title="Due Diligence">
                          <IconButton
                            color="secondary"
                            onClick={() => {
                              const url = isInternal
                                ? 'document-repository'
                                : 'third-party-repository'
                              window.open(
                                `${process.env.REACT_APP_CMS_SITE}/${url}?businessId=${portfolioDetailData?.sellerId}`,
                                '_blank'
                              )
                            }}
                          >
                            <Icon name="Feed" fontSize="medium" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    )}
                  {portfolioDetailData?.isDisclosureEnabled && (
                    <Box
                      display="flex"
                      pr={4}
                      style={{ verticalAlign: 'middle' }}
                    >
                      <IconButton
                        onClick={() => {
                          setOpenDisclosure(true)
                        }}
                      >
                        <Icon
                          name="Help"
                          fontSize="medium"
                          color={Colors.error}
                        />
                      </IconButton>
                    </Box>
                  )}

                  {!loading &&
                    portfolioDetailData &&
                    portfolioDetailData?.isDisclosureEnabled && (
                      <ModalDialog
                        isOpen={openDisclosure}
                        header="Disclosure"
                        hideCancelButton
                        buttonOkText="Close"
                        onContinue={handleCloseDisclosure}
                        onClose={handleCloseDisclosure}
                      >
                        <Box
                          color={Colors.black}
                          style={{ fontSize: '1rem', whiteSpace: 'pre-line' }}
                        >
                          {convertLinks(
                            portfolioDetailData?.disclosureText || ''
                          )}
                        </Box>
                      </ModalDialog>
                    )}
                  {portfolioDetailData?.forwardFlowAgreementId && (
                    <Box display="flex" alignItems="center" pr={4}>
                      <Typography color={textSecondary.color}>
                        {' FF:'}
                      </Typography>
                      <IconButton
                        size="small"
                        onClick={(): void => {
                          handleForwardFlowClick(
                            portfolioDetailData?.forwardFlowAgreementId
                          )
                        }}
                      >
                        <Typography color={textSecondary.color}>
                          {portfolioDetailData?.forwardFlowAgreementId}
                        </Typography>
                      </IconButton>
                    </Box>
                  )}
                </Box>

                {portfolioDetailData?.bidCloseUtc && (
                  <Box display="flex" alignItems="center">
                    <DisplayAuction
                      deadline={portfolioDetailData?.bidCloseUtc}
                      loading={loading}
                      screen="large"
                    />
                  </Box>
                )}
                <Box display="flex" alignItems="center" pl={4}>
                  <SalesDirectorInfo
                    portfolioStatus={portfolioDetailData?.status}
                    infos={portfolioDetailData?.salesDirector}
                  />
                </Box>
              </Box>
            </Grid>
          )}
          <Grid item xs={12} sm={12}>
            <HistorySteps
              statusHistory={portfolioDetailData?.statusHistory}
              loading={loading}
            />
          </Grid>
        </Grid>

        {/* Portfolio Aggregates, Collection activity and actions */}
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <PortfolioInfoAccordion
              portfolioDetailData={portfolioDetailData}
              loadingPortfolioInfo={loading}
              userType={userType}
            />
            <DisplayAggregates
              loading={loading}
              aggregatesData={portfolioDetailData?.summaryData}
            />
            <PortfolioCollectionActivityDisplay portfolioId={portfolioId} />
          </Grid>
          <Grid item xs={12} md={6}>
            {!portfolioDetailData && (
              <Skeleton variant="rectangular" width="100%" height={50} />
            )}
            {portfolioDetailData?.status.toLowerCase() !==
              PortfolioStatusType.WaitingForScrubResults &&
              portfolioDetailData?.status.toLowerCase() !==
                PortfolioStatusType.SubmittingForScrubResults &&
              showScrubComponent && (
                <SubmitScrub
                  portfolio={portfolioDetailData}
                  openAccountErrorWarning={
                    (type: string) => openErrorsWarnings(type)
                    // eslint-disable-next-line react/jsx-curly-newline
                  }
                />
              )}
            {showScrubAwaitingSellerComponent && (
              <SubmitScrubAwaitingSeller
                portfolioStatus={portfolioDetailData?.status}
                winningbidid={portfolioDetailData?.winningBidId}
              />
            )}
            {showSellerBkFFAwardedAction && (
              <SellerAwardedAction
                portfolioData={portfolioDetailData}
                loadingPortfolioData={loading}
              />
            )}
            {showUpdateCloseFundingDate && (
              <UpdateCloseFundDates
                portfolioId={portfolioDetailData?.id}
                forwardFlowId={portfolioDetailData?.forwardFlowAgreementId}
                isBuyer={isPortfolioBuyer}
                portfolioStatus={portfolioDetailData?.status}
                winningbidid={portfolioDetailData?.winningBidId}
                isLoading={loading}
              />
            )}
            {showConfirmSellerFee && (
              <ConfirmSellerFee
                portfolioId={portfolioDetailData?.id}
                buyerName={portfolioDetailData?.buyerName}
                sellerName={portfolioDetailData?.sellerName}
                hasBOS={portfolioDetailData?.hasBos || false}
                hasFinalBOS={portfolioDetailData?.hasFinalBos || false}
                hasSellerFeeConfirmed={
                  portfolioDetailData?.hasSellerFeeConfirmed || false
                }
                portfolioHasBOSReleased={
                  portfolioDetailData?.isBosReleased || false
                }
                portfolioHasBOSConfirmed={
                  portfolioDetailData?.isBosConfirmed || false
                }
                portfolioNumber={portfolioDetailData?.portfolioNumber}
                portfolioCountry={portfolioDetailData?.portfolioCountry}
                portfolioStatus={portfolioDetailData?.status}
                eSignatureEnabledBOS={Boolean(
                  portfolioDetailData?.eSignatureEnabledBOS
                )}
                isBosESignatureSent={Boolean(
                  portfolioDetailData?.isBosESignatureSent
                )}
                bosSignerName={portfolioDetailData?.bosSignerName}
                bosSignerTitle={portfolioDetailData?.bosSignerTitle}
                bosSignerEmail={portfolioDetailData?.bosSignerEmail}
                sellerUploadTemplateId={
                  portfolioDetailData?.sellerUploadTemplateId
                }
              />
            )}
            {showPurchaseSaleAgreement && (
              <PurchaseSaleAgreement
                portfolioId={portfolioDetailData?.id}
                sellerId={portfolioDetailData?.sellerId || ''}
                sellerUploadTemplateId={
                  portfolioDetailData?.sellerUploadTemplateId || 0
                }
                forwardFlowAgreementId={
                  portfolioDetailData?.forwardFlowAgreementId
                }
                userBusinessType={
                  // eslint-disable-next-line no-nested-ternary
                  isPortfolioSeller
                    ? SELLER
                    : isPortfolioBuyer
                    ? BUYER
                    : INTERNAL
                }
                portfolioCountry={portfolioCountry}
                eSignatureEnabledPSASeller={Boolean(
                  portfolioDetailData?.eSignatureEnabledPSASeller
                )}
                eSignatureEnabledPSABuyer={Boolean(
                  portfolioDetailData?.eSignatureEnabledPSABuyer
                )}
                isESignatureSetBuyerPSA={Boolean(
                  portfolioDetailData?.isESignatureSetBuyerPSA
                )}
                isPSAESignatureSent={Boolean(
                  portfolioDetailData?.isPSAESignatureSent
                )}
                hasFinalPSA={Boolean(portfolioDetailData?.hasFinalPSA)}
                loadingPortfolioDetails={loading}
              />
            )}
            {showFundingInformation && (
              <FundingInformation
                portfolioId={portfolioDetailData?.id}
                portfolioStatus={pStatus}
                buyerName={portfolioDetailData?.buyerName}
                sellerName={portfolioDetailData?.sellerName}
                isPortfolioBuyer={isPortfolioBuyer}
                isPortfolioSeller={isPortfolioSeller}
                hasBOS={portfolioDetailData?.hasBos || false}
                hasFinalBOS={portfolioDetailData?.hasFinalBos || false}
                portfolioHasBOSReleased={
                  portfolioDetailData?.isBosReleased || false
                }
                portfolioHasBOSConfirmed={
                  portfolioDetailData?.isBosConfirmed || false
                }
                isInternal={isInternal}
                portfolioCountry={portfolioCountry}
                eSignatureEnabledBOS={Boolean(
                  portfolioDetailData?.eSignatureEnabledBOS
                )}
                isBosESignatureSent={Boolean(
                  portfolioDetailData?.isBosESignatureSent
                )}
                bosSignerName={portfolioDetailData?.bosSignerName}
                bosSignerTitle={portfolioDetailData?.bosSignerTitle}
                bosSignerEmail={portfolioDetailData?.bosSignerEmail}
                sellerUploadTemplateId={
                  portfolioDetailData?.sellerUploadTemplateId
                }
              />
            )}

            {showSellerReviewForwardFlow && (
              <SellerReviewForwardFlow portfolio={portfolioDetailData} />
            )}

            {showListPortfolio && showSellerReview && (
              <ListPortfolio
                portfolio={portfolioDetailData}
                portfolioFileLinkData={portfolioFiles}
                loadingPortfolioFileLink={loadingPortfolioFiles}
                openAccountScrubRemoved={() => openScrubRemoved()}
              />
            )}
            {showBuyerFundingInformation && (
              <ConfirmBuyerFunding
                portfolioId={portfolioDetailData?.id}
                portfolioStatus={pStatus}
                portfolioCountry={portfolioCountry}
              />
            )}
            {showConfirmSellerFunding && (
              <ConfirmSellerFunding
                portfolioId={portfolioDetailData?.id}
                portfolioStatus={pStatus}
                portfolioNumber={portfolioDetailData?.portfolioNumber}
                portfolioCountry={portfolioCountry}
              />
            )}
            {showFinalFundingConfirmation && (
              <ConfirmFinalFunding
                loading={loading}
                portfolioId={portfolioDetailData?.id}
                buyerName={portfolioDetailData?.buyerName}
                sellerName={portfolioDetailData?.sellerName}
                portfolioStatus={pStatus}
                portfolioCloseDate={portfolioDetailData?.portfolioCloseUtc}
                portfolioFundDate={portfolioDetailData?.fundingDateUtc}
                portfolioInitialPsaDate={portfolioDetailData?.initialPsaDateUtc}
                hasBOS={portfolioDetailData?.hasBos || false}
                hasFinalBOS={portfolioDetailData?.hasFinalBos || false}
                portfolioHasBOSReleased={
                  portfolioDetailData?.isBosReleased || false
                }
                portfolioHasBOSConfirmed={
                  portfolioDetailData?.isBosConfirmed || false
                }
                portfolioNumber={portfolioDetailData?.portfolioNumber}
                portfolioCountry={portfolioCountry}
                eSignatureEnabledBOS={Boolean(
                  portfolioDetailData?.eSignatureEnabledBOS
                )}
                isBosESignatureSent={Boolean(
                  portfolioDetailData?.isBosESignatureSent
                )}
                bosSignerName={portfolioDetailData?.bosSignerName}
                bosSignerTitle={portfolioDetailData?.bosSignerTitle}
                bosSignerEmail={portfolioDetailData?.bosSignerEmail}
                sellerUploadTemplateId={
                  portfolioDetailData?.sellerUploadTemplateId
                }
              />
            )}

            {showStatusPortfolio && (
              <DTAccordion
                id="status-accordion"
                title="Status"
                icon={<Icon name="Restore" />}
                expanded={true}
              >
                <Typography variant="h2">
                  {pStatus === PortfolioStatusType.WaitingForScrubResults
                    ? 'A notification will be sent once the portfolio returns from scrub.'
                    : 'A notification will be sent once the portfolio returns from scrub.'}
                </Typography>
              </DTAccordion>
            )}
            {showSellerBidSummary && (
              <SellerBidSummary
                portfolioId={portfolioId}
                portfolioCountry={portfolioCountry}
                expanded={pStatus === PortfolioStatusType.Listed}
              />
            )}
            {showBidReview && (
              <BidReview
                portfolioId={portfolioId}
                isPortfolioBkType={portfolioDetailData?.portfolioTypeId === 2}
                bidAccepted={undefined}
                portfolioCountry={portfolioCountry}
              />
            )}
            {showApproveUnmaskData && (
              <ApproveUnmaskedDataRequest
                portfolioId={portfolioId || ''}
                portfolioStatus={pStatus}
              />
            )}
            {showBuyerAccess && <BuyerAccess portfolioId={portfolioId} />}

            {showUnmaskData &&
              (userPermissions.type !== 'Buyer' ||
                (userPermissions.type === BUYER &&
                  userPermissions.isReseller &&
                  userType?.userType === SELLER)) && (
                <UnmaskDataRequest
                  portfolioId={portfolioId}
                  buyerBidsData={buyerBidsData}
                  auctionClosed={isAuctionClosed}
                />
              )}
            {showInvoices &&
              ability.can(
                PermissionCodeAccess.MarketPlace_Internal_ResendDownloadInvoices,
                'any'
              ) && (
                <PortfolioInvoices
                  portfolioId={portfolioId}
                  portfolioStatus={portfolioDetailData?.status}
                />
              )}
            {showPortfolioHistory && (
              <PortfolioHistory portfolioId={portfolioId} />
            )}
            {showAdminRegenerateFile && (
              <AdminRegenerateFile
                portfolioId={portfolioId}
                allowUnmaskData={
                  portfolioDetailData?.allowAdditionalUnmaskedData || false
                }
              />
            )}
            {showAdminCloseAuction && (
              <AdminCloseAuction portfolioId={portfolioId} />
            )}
            {showListPortfolioImmediately && (
              <ListPortfolioImmediately
                portfolio={portfolioDetailData}
                modifyListPortfolioImmediately={modifyListPortfolioImmediately}
              />
            )}
            {showAdminUpdateBuyerFee && (
              <UpdateBuyerFee
                portfolio={portfolioDetailData}
                portfolioCountry={portfolioCountry}
              />
            )}
            {showAdminUpdateSellerFee && (
              <UpdateSellerFee
                portfolio={portfolioDetailData}
                portfolioCountry={portfolioCountry}
              />
            )}
            {showAdminSaveBid && <SaveBid portfolioId={portfolioId} />}
            {showAdminDisclosure && (
              <AdminAddDisclosure portfolioId={portfolioId} />
            )}
            {showAdminAddDownload && (
              <AdminAddDownload
                portfolioId={portfolioId}
                status={pStatus}
                showSCRA={portfolioDetailData?.hasSCRACert || false}
              />
            )}
            {showAdminChangeBuyer && (
              <AdminChangeBuyer portfolioId={portfolioId} />
            )}
            {!!portfolioDetailData?.numberOfAccounts &&
              (!loading ? (
                <Box
                  width="100%"
                  height={50}
                  onClick={() => {
                    setOpenAnalysis(true)
                  }}
                >
                  <Box mt={5} display="flex-end">
                    <Accordion expanded={false} id="analysis-accordion">
                      <AccordionSummaryCustom2
                        expandIcon={<Icon name="ExpandMore" />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Box display="flex" flexDirection="row">
                          <Box pr={3}>
                            <Icon name="PieChart" />
                          </Box>
                          <AccordionTitle>Analysis</AccordionTitle>
                        </Box>
                      </AccordionSummaryCustom2>
                    </Accordion>
                  </Box>
                </Box>
              ) : (
                <Box paddingTop="50px">
                  <Skeleton variant="rectangular" width="100%" height={50} />
                </Box>
              ))}
            {statusesToShowPostSaleAccordion.includes(pStatus) && (
              <PostSaleRequestAccordion portfolioId={portfolioId} />
            )}
            {!loading ? (
              <Box
                width="100%"
                height={50}
                onClick={() => {
                  handleSetOpenAccounts(true)
                }}
              >
                <Box mt={5} display="flex-end">
                  <Accordion expanded={false} id="accounts-accordion">
                    <AccordionSummaryCustom2
                      expandIcon={<Icon name="ExpandMore" />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Box display="flex" flexDirection="row">
                        <Box pr={3}>
                          <Icon name="AccountBox" />
                        </Box>
                        <AccordionTitle>Accounts</AccordionTitle>
                      </Box>
                    </AccordionSummaryCustom2>
                  </Accordion>
                </Box>
              </Box>
            ) : (
              <Box paddingTop="50px">
                <Skeleton variant="rectangular" width="100%" height={50} />
              </Box>
            )}
            {showInternalSellerSurvey && (
              <SellerSurveyFile portfolio={portfolioDetailData} />
            )}
            {showScoringRequest && (
              <ScoringRequest
                portfolioNumber={portfolioDetailData?.portfolioNumber}
                totalNumberOfAccounts={
                  portfolioDetailData?.numberOfAccounts || 0
                }
                buyerBidsData={buyerBidsData}
              />
            )}
            {showUnmaskData && userPermissions.type === 'Buyer' && (
              <UnmaskDataRequest
                portfolioId={portfolioId}
                buyerBidsData={buyerBidsData}
                auctionClosed={isAuctionClosed}
              />
            )}
            {loadingPortfolioFiles ? (
              <Box mt={5}>
                <Skeleton variant="rectangular" width="100%" height={50} />
              </Box>
            ) : (
              <PortfolioFileLinks
                portfolioId={portfolioId}
                portfolioFiles={portfolioFiles}
                hideUserColumn={userPermissions.type === 'Buyer'}
                portfolioTypeId={portfolioDetailData?.portfolioTypeId}
                portfolioCountry={portfolioDetailData?.portfolioCountry}
                businessId={portfolioDetailData?.sellerId}
                surveyId={portfolioDetailData?.surveyId}
                fetchedPortfolioData={fetchedPortfolioData}
              />
            )}
            {(showPortfolioMediaFilesToSeller ||
              showPortfolioMediaFilesToBuyer ||
              isInternal) &&
              (loadingPortfolioMediaFiles ? (
                <Box mt={5}>
                  <Skeleton variant="rectangular" width="100%" height={50} />
                </Box>
              ) : (
                <PortfolioMediaFileLinks
                  portfolioId={portfolioId}
                  portfolioNumber={portfolioDetailData?.portfolioNumber}
                  portfolioMediaFiles={portfolioMediaFiles}
                  isInternal={isInternal}
                  canUserUpload={canUploadMediaFile}
                  refetchMediaFileLinks={refetchMediaFileLinks}
                  portfolioStatus={pStatus}
                />
              ))}
            {showBuyerPlaceBid && (
              <BuyerPlaceBid
                portfolioCountry={portfolioDetailData?.portfolioCountry}
                portfolioId={portfolioId}
                portfolioNumber={portfolioDetailData?.portfolioNumber}
                portfolioTypeId={portfolioDetailData?.portfolioTypeId}
                portfolioFaceValue={portfolioDetailData?.totalFaceValue}
                portfolioTemplateId={
                  portfolioDetailData?.sellerUploadTemplateId
                }
                forwardFlowAgreementId={
                  portfolioDetailData?.forwardFlowAgreementId
                }
                canCompleteFFReview={
                  portfolioDetailData?.canCompleteFFReview || false
                }
                buyerBidsData={buyerBidsData}
                loadingPlacedBids={loadingPlacedBids}
                showDisclosure={portfolioDetailData?.isDisclosureEnabled}
                onOpenDisclosureModal={setOpenDisclosure}
              />
            )}
          </Grid>
        </Grid>
      </Content>
      <ModalDialog
        id="boxAnalysis"
        header="Analysis"
        onClose={() => {
          setOpenAnalysis(false)
        }}
        isOpen={openAnalysis}
        width="90%"
        style={{ height: window.innerHeight * 0.8, zIndex: 2 }}
        isForm
      >
        <PortfolioAnalyticsGraphcs
          isAutoPortfolio={portfolioDetailData?.isAutoPortfolio ?? false}
          portfolioId={portfolioId}
          lastTimeUpdated={lastDatePortfolioAnalytics ?? 'N/A'}
          portfolioNumber={portfolioDetailData?.portfolioNumber ?? ''}
          portfolioCountry={portfolioDetailData?.portfolioCountry}
        />
      </ModalDialog>
      <ModalDialog
        id="boxAccount"
        header="Accounts"
        onClose={() => {
          handleSetOpenAccounts(false)
        }}
        isOpen={openAccounts}
        width="90%"
        style={{ height: window.innerHeight * 0.8, zIndex: 2 }}
        isForm
      >
        {portfolioAccounts ? (
          <Box
            display="flex"
            flexDirection="column"
            maxWidth="100%"
            maxHeight="95%"
          >
            <TabContext value={tabDetailsIndex}>
              <Tabs
                value={tabDetailsIndex}
                onChange={(e, newValue) => {
                  setTabDetailsIndex(newValue)
                  ResetAccountsGrid()
                }}
                indicatorColor="primary"
                textColor="primary"
                centered
              >
                <TabCustom
                  label={`All${
                    portfolioAccounts?.totalOfAllAccounts
                      ? ` (${portfolioAccounts?.totalOfAllAccounts})`
                      : ''
                  }`}
                  value=""
                  disabled={disablePortfolioAccountsUI()}
                />
                {(isPortfolioSeller || isInternal) && (
                  <TabCustom
                    label={`Data Warnings${
                      portfolioAccounts?.totalOfAccountsWithWarning
                        ? ` (${
                            portfolioAccounts?.totalOfAccountsWithWarning
                          } -  ${numberToPercentage(
                            portfolioAccounts?.percentOfAccountsWithWarning
                          )}) `
                        : ''
                    }`}
                    value="warnings"
                    disabled={disablePortfolioAccountsUI()}
                  />
                )}
                {(isPortfolioSeller || isInternal) && (
                  <TabCustom
                    label={`Validation Errors${
                      portfolioAccounts?.totalOfAccountsWithError
                        ? ` (${
                            portfolioAccounts?.totalOfAccountsWithError
                          } -  ${numberToPercentage(
                            portfolioAccounts?.percentOfAccountsWithError
                          )})`
                        : ''
                    }`}
                    value="errors"
                    disabled={disablePortfolioAccountsUI()}
                  />
                )}
                {(isPortfolioSeller || isInternal) && (
                  <TabCustom
                    label={`COT Errors${
                      portfolioAccounts?.totalOfAccountsExcluded
                        ? ` (${
                            portfolioAccounts?.totalOfAccountsExcluded
                          } -  ${numberToPercentage(
                            portfolioAccounts?.percentOfAccountsExcluded
                          )})`
                        : ''
                    }`}
                    value="excluded"
                    disabled={disablePortfolioAccountsUI()}
                  />
                )}
                <TabCustom
                  label={`Seller Removed${
                    portfolioAccounts?.totalOfAccountsSellerRemoved
                      ? ` (${
                          portfolioAccounts?.totalOfAccountsSellerRemoved
                        } -  ${numberToPercentage(
                          portfolioAccounts?.percentOfAccountsSellerRemoved
                        )})`
                      : ''
                  }`}
                  value="sellerRemoved"
                  disabled={disablePortfolioAccountsUI()}
                />

                {portfolioDetailData?.portfolioTypeId === 2 &&
                  pStatus !== PortfolioStatusType.ReadyForReview &&
                  pStatus !== PortfolioStatusType.Listed && (
                    <TabCustom
                      label={`Buyer Removed${
                        portfolioAccounts?.totalOfAccountsBuyerRemoved
                          ? ` (${
                              portfolioAccounts?.totalOfAccountsBuyerRemoved
                            } -  ${numberToPercentage(
                              portfolioAccounts?.percentOfAccountsBuyerRemoved
                            )})`
                          : ''
                      }`}
                      value="buyerRemoved"
                      disabled={disablePortfolioAccountsUI()}
                    />
                  )}
                {userPermissions.type !== 'Buyer' &&
                  portfolioDetailData?.portfolioTypeId !== 2 &&
                  portfolioDetailData?.hasScrub && (
                    <TabCustom
                      label={`Scrub Removed${
                        portfolioAccounts?.totalOfAccountsScrubRemoved
                          ? ` (${
                              portfolioAccounts?.totalOfAccountsScrubRemoved
                            } -  ${numberToPercentage(
                              portfolioAccounts?.percentOfAccountsScrubRemoved
                            )})`
                          : ''
                      }`}
                      value="scrubRemoved"
                      disabled={disablePortfolioAccountsUI()}
                    />
                  )}
                {renderNoScrubbedTab() && (
                  <TabCustom
                    label={
                      <div>
                        {noScrubText()}{' '}
                        <Tooltip
                          title="Contact Recovery Success for more info."
                          aria-label="portfolio"
                        >
                          <Icon
                            name="ErrorOutline"
                            style={{
                              verticalAlign: 'middle',
                              marginBottom: 2,
                              marginRight: 6,
                            }}
                            fontSize="small"
                          />
                        </Tooltip>
                      </div>
                    }
                    value="scrubbedNoSale"
                    disabled={disablePortfolioAccountsUI()}
                  />
                )}
                {/* <TabCustom
                                                label={`Scrubbed Eligible Sale${portfolioAccounts?.portfolioDetailAccounts
                                                    ?.totalOfAccountsExcluded
                                                    ? ` (${portfolioAccounts?.portfolioDetailAccounts?.totalOfAccountsExcluded})`
                                                    : ''
                                                    }`}
                                                value="scrubbedEligibleSale"
                                                disabled={disablePortfolioAccountsUI()}
                                            /> */}
              </Tabs>
            </TabContext>
            {loadingAccount || updatingAccountsByRow ? (
              <Box mt={5}>{loadingPanel}</Box>
            ) : (
              <>
                <Flex p={2}>
                  <Box
                    alignItems="center"
                    display="flex"
                    flexDirection="row"
                    flexGrow={1}
                  >
                    {(tabDetailsIndex === '' ||
                      tabDetailsIndex === 'excluded') &&
                      isPortfolioSeller &&
                      portfolioDetailData &&
                      ['ready for review', 'listed', 'awarded'].includes(
                        portfolioDetailData?.status.toLowerCase()
                      ) && (
                        <>
                          <Box p={2}>
                            <Typography variant="h2">
                              {tabDetailsIndex === '' ? 'Exclude' : 'Restore'}
                            </Typography>
                          </Box>
                          <Box
                            width={250}
                            hidden={tabDetailsIndex === 'excluded'}
                          >
                            <Autocomplete
                              style={{ zIndex: 3 }}
                              freeSolo
                              fullWidth
                              disabled={disablePortfolioAccountsUI()}
                              options={['Invalid data', 'Collected']}
                              value={removeAccountsReason || ''}
                              onInputChange={(event, value) => {
                                setRemoveAccountsReason(value)
                              }}
                              renderInput={(params: any) => (
                                <TextField
                                  {...params}
                                  placeholder="Select or enter exclude reason"
                                />
                              )}
                            />
                          </Box>
                          <Box p={2}>
                            <Button
                              title="Update accounts"
                              disabled={
                                disableManageAccountsButton() ||
                                disablePortfolioAccountsUI()
                              }
                              startIcon={<Icon name="Delete" />}
                              onClick={onManageAccounts}
                            >
                              {getManageAccountsButtonText()}
                            </Button>
                          </Box>
                          <Box p={2}>
                            <Button
                              title="Exclude out of stat accounts"
                              startIcon={<Icon name="Clear" />}
                              onClick={() => {
                                setOpenExcludeOutOfStatModal(true)
                              }}
                              disabled={
                                portfolioAccounts?.totalOfAccountsOutOfStats ===
                                  0 || disablePortfolioAccountsUI()
                              }
                            >
                              Exclude out of stat accounts
                            </Button>
                          </Box>
                          <Box p={2}>
                            <ButtonDropdown
                              id="bulk-manage-accounts-upload-file"
                              buttontext="Exclude multiple accounts"
                              width="230px"
                              isFetching={isFetchingDownloadTemplateFile}
                              onClickButton={() =>
                                setShowManageAccountsFileModal(true)
                              }
                            >
                              <ButtonDropdownItem
                                onClick={() => setIsGettingTemplateFile(true)}
                              >
                                Download Template
                              </ButtonDropdownItem>
                            </ButtonDropdown>
                          </Box>
                        </>
                      )}
                  </Box>
                  <Box display="flex" justifyContent="end">
                    <ExportExcelButton
                      data={formatDataToExport(
                        accounts,
                        portfolioRowColumns,
                        profileClient
                      )}
                      fileName={`Portfolio ${
                        portfolioDetailData?.portfolioNumber
                      }${
                        tabDetailsIndex !== '' ? ` - ${tabDetailsIndex}` : ''
                      }`}
                      variant="secondary"
                      disabled={loadingAccount}
                    >
                      Export page to Excel
                    </ExportExcelButton>
                  </Box>
                </Flex>
                <DataTable
                  className="accountgrid"
                  sortable
                  useFilterMenu
                  pageable={{
                    pageSizes: [25, 50, 100, 500, 1000],
                  }}
                  total={totalDataSize}
                  skip={gridState.skip}
                  take={gridState.take}
                  pageSize={gridState.take}
                  filter={gridState.filter}
                  sort={gridState.sort}
                  data={accounts}
                  onDataStateChange={(e) => {
                    setGridState(e.dataState)
                  }}
                  gridColumns={portfolioRowColumns}
                />
              </>
            )}
          </Box>
        ) : (
          <Box mt={5} width="100%" height={400}>
            {loadingPanel}
          </Box>
        )}
      </ModalDialog>

      <ModalDialog
        isOpen={openExcludeOutOfStatModal}
        header="Confirm"
        onClose={() => {
          setOpenExcludeOutOfStatModal(false)
        }}
        onContinue={onExcludeOutOfStat}
        isFetching={excludingAccountsOutOfStat}
        buttonOkText="Confirm"
      >
        <Grid container direction="column">
          <Grid item>
            <Typography>
              Would you like to exclude all out of stat accounts?
            </Typography>
          </Grid>
        </Grid>
      </ModalDialog>

      <ModalDialog
        isOpen={openDeleteCofirm}
        header="Delete Portfolio"
        onClose={handleClose}
        onContinue={handlePurgePortfolio}
        buttonOkText="Confirm"
        isFetching={loadingPurge}
      >
        <Box>
          <Typography variant="body1" color={textSecondary.color}>
            {`Are you sure want to delete portfolio ${
              portfolioDetailData?.portfolioNumber
                ? portfolioDetailData?.portfolioNumber
                : ''
            }?`}
          </Typography>
          <Typography variant="body1" color={textSecondary.color}>
            All data related to this portfolio will be deleted from the
            platform.
          </Typography>
          {!portfolioDetailData?.canDeleteAllPortfolioData && (
            <TextField
              label="Reason"
              value={reason.value}
              onChange={handleReason}
              error={Boolean(reason.error)}
              helperText={reason.error}
              disabled={loadingPurge}
              fullWidth
            />
          )}
        </Box>
      </ModalDialog>

      <ModalDialog
        isOpen={showManageAccountsFileModal}
        header="Upload File"
        onClose={() => {
          setShowManageAccountsFileModal(false)
          setUploadFile(undefined)
        }}
        onContinue={handleUploadFile}
        buttonOkText="Upload"
        width="50%"
        isFetching={updatingAccountsByLender}
        disableOkButton={!uploadFile}
      >
        <Box padding={2}>
          <UploadDragDrop
            files={uploadFile ? [uploadFile] : []}
            setFiles={(file) => setUploadFile(file?.[0])}
            hideUploadButton
          />
        </Box>
      </ModalDialog>
    </SectionColumn>
  )
}

export default PortfolioDetail
